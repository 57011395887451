import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Select from 'react-select';

export const PropertyTypes = (props) => {
  const data = useStaticQuery(graphql`  
  query PropertyTypesQuery { 
    glstrapi {
      siteConfig {
        Commercial_Property_Types {
          Label
          Value
        }
        Commercial_Rental_Property_Types {
          Label
          Value
        }
        Residential_Property_Types {
          Label
          Value
        }
        Residential_Rental_Property_Types {
          Label
          Value
        }
      }
    }
  }
`)

  let typeList = data.glstrapi.siteConfig;
  // console.log('PropertyTypes', data.glstrapi.siteConfig)
  let typeName = "Residential_Property_Types"
  if (props.propertyTypeName) {
    typeName = props.propertyTypeName
  }
  return (
    <>
      <Select
        options={typeList[typeName].map(({ Label, Value }) => ({ label: Label, value: Value }))}
        {...props}
      />
    </>
  )
}

 